import $ from "jquery";
import "what-input";
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
import Swiper from "swiper";

import OverlayScrollbars from "overlayscrollbars/js/OverlayScrollbars";

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require("foundation-sites");

require("foundation-inline-svg/js/foundation.inline-svg.js");
require("./custom/video-bg");
require("./custom/jquery.swipebox");

$(document).foundation();
window.viewstate = {};

/**
 * @author Gestycontrol
 */
// (function ($) {
//   $.fn.closestDescendent = function (filter) {
//     let $found = $(),
//       $currentSet = this; // Current place
//     while ($currentSet.length) {
//       $found = $currentSet.filter(filter);
//       if ($found.length) break; // At least one match: break loop
//       // Get all children of the current set
//       $currentSet = $currentSet.children();
//     }
//     return $found.first(); // Return first match of the collection
//   };
// })(jQuery);

/**
 * @author Gestycontrol
 */
// (function ($) {
//   $.fn.disableByReason = function (identifier) {
//     return this.toggleDisableByReason(identifier, true);
//   };

//   $.fn.enableByReason = function (identifier) {
//     return this.toggleDisableByReason(identifier, false);
//   };

//   $.fn.toggleDisableByReason = function (identifier, disabled) {
//     if (!identifier) {
//       throw "Parameter identifier is required for Jquery.fn.disableByReason";
//     }

//     this.each(function () {
//       let $el = $(this);

//       if (!this.disabledReasons) {
//         this.disabledReasons = [];
//       }

//       if (this.disabledReasons.length === 0 && $(this).is("[disabled]")) {
//         this.disabledReasons.push("html");
//       }

//       let index = this.disabledReasons.indexOf(identifier);

//       if (disabled) {
//         if (index === -1) {
//           this.disabledReasons.push(identifier);
//         }
//       } else {
//         if (index > -1) {
//           this.disabledReasons.splice(index, 1);
//         }
//       }

//       if (this.disabledReasons.length === 0) {
//         $el.removeAttr("disabled");
//       } else {
//         $el.attr("disabled", "disabled");
//       }
//     });

//     return this;
//   };
// })(jQuery);

/**
 * @author Gestycontrol
 */
(function ($) {
  /**
   * Filters the list of elements keeping only the elements that were not already filtered with the same identifier
   *
   * @param identifier string name of the filter (unique)
   */
  $.fn.once = function (identifier) {
    if (!identifier) {
      throw "Parameter identifier is required for Jquery.fn.once";
    }

    // Filter
    return this.filter(function () {
      // Add prefix to identifier avoid reserved words
      identifier = "jquery.once." + identifier;

      // Skip elements that already have the identifier
      if (this[identifier] !== undefined) {
        return false;
      }

      // Add the identifier
      this[identifier] = true;

      // Keep elements that did not have the identifier set
      return true;
    });
  };
})(jQuery);

var addons = {
  init: function () {
    // addons.pagination();
    addons.maps();
  },
  pagination: function () {},
  maps: function () {
    $(".tabs")
      .once("tab-change")
      .on("change.zf.tabs", function (e) {
        let has_maps = false;

        $(".tabs-title.is-active a").each(function () {
          try {
            let $activeTab = $($(this).attr("href")),
              $innerMap = $activeTab.find(".leaflet-container");

            if ($innerMap.length > 0) {
              if ($innerMap.data("map")) {
                $innerMap.data("map").invalidateSize();
              }

              if ($innerMap.data("mapPopupMarker")) {
                $innerMap.data("mapPopupMarker").openPopup();
              }
            }
          } catch (e) {}
        });
      });

    $("[data-map-coord-lat]")
      .once("initialize-leaflet")
      .each(function () {
        let osmUrl =
            "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
          osmAttrib =
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
          osm = L.tileLayer(osmUrl, { maxZoom: 18, attribution: osmAttrib }),
          lat = $(this).data("map-coord-lat"),
          lng = $(this).data("map-coord-lng"),
          zoom = $(this).data("map-zoom"),
          popup_title = $(this).data("map-title"),
          popup_content = $(this).data("map-text"),
          popup_img = $(this).data("map-img"),
          map = L.map(this, { scrollWheelZoom: false }),
          customIcon = L.icon({
            iconUrl:
              "/wp-content/themes/inmoglaciar/dist/static/img/marker/marker.png",
            iconSize: [102, 114],
            iconAnchor: [51, 80],
            popupAnchor: [0, -56],
          });

        map.setView([lat, lng], zoom);
        map.addLayer(osm);
        let marker = L.marker([lat, lng], { icon: customIcon }).addTo(map);
        $(this).data("map", map);

        if (popup_title && popup_content && popup_img) {
          let popup = L.popup({
            maxWidth: 450,
            maxHeight: 180,
          }).setContent(
            '<div class="map-result"><div class="map-result__image" style="background-image:url(' +
              popup_img +
              ')"></div>' +
              '<div class="map-result__text">' +
              '<h3 class="title">' +
              popup_title +
              "</h3>" +
              '<p class="description">' +
              popup_content +
              "</p></div></div>"
          );
          marker.bindPopup(popup);

          if ($(this).is(":visible")) {
            marker.openPopup();
          }

          $(this).data("mapPopupMarker", marker);
        }
      });
  },
};

// window.subdictionarySelect = {
//   init: function ($form) {
//     $form
//       .find(".dictionary[data-dictionary-model]")
//       .once("initialize-subdictionary-form")
//       .each(function () {
//         window.subdictionarySelect._setListeners(
//           $(this).parents("form"),
//           $(this).data("dictionary-model"),
//           $(this).data("dictionary-context")
//         );
//       });
//   },
//   _setListeners: function ($form, dictionary_model, dictionary_context) {
//     var $dictionarySelect = $form.find(
//         '.dictionary[data-dictionary-model="' +
//           dictionary_model +
//           '"][data-dictionary-context="' +
//           dictionary_context +
//           '"]'
//       ),
//       $subdictionarySelect = $form.find(
//         '.subdictionary[data-dictionary-model="' +
//           dictionary_model +
//           '"][data-dictionary-context="' +
//           dictionary_context +
//           '"]'
//       );

//     var change = function (e, data) {
//       $subdictionarySelect.each(function () {
//         var $this = $(this);

//         $this.data("old_val", $this.val());
//         $this.disableByReason("subdictionary").find("option").remove();

//         if ($this.is("[data-empty-text]")) {
//           $this.append(
//             $("<option>")
//               .attr({
//                 value: "",
//               })
//               .text($this.data("empty-text"))
//           );
//         }
//       });

//       if ($dictionarySelect.val()) {
//         window.getSubdictionariesByDictionaryId(
//           $dictionarySelect.val(),
//           dictionary_context,
//           dictionary_model,
//           function (result) {
//             // Avoid duplicate calls
//             $subdictionarySelect.each(function () {
//               var $this = $(this);

//               //$this.data('old_val', $this.val());
//               $this.disableByReason("subdictionary").find("option").remove();

//               if (
//                 result.response.keys.length !== 1 ||
//                 !$(this).is("[required]")
//               ) {
//                 if ($this.is("[data-empty-text]")) {
//                   $this.append(
//                     $("<option>")
//                       .attr({
//                         value: "",
//                       })
//                       .text($this.data("empty-text"))
//                   );
//                 }
//               }
//             });

//             for (var i = 0; i < result.response.keys.length; i++) {
//               $subdictionarySelect.append(
//                 $("<option>")
//                   .attr({
//                     value: result.response.keys[i],
//                   })
//                   .text(result.response.values[result.response.keys[i]])
//               );
//             }

//             if (
//               result.response.keys.length > 0 &&
//               !$dictionarySelect.is("[disabled]")
//             ) {
//               $subdictionarySelect.enableByReason("subdictionary");
//             }

//             $subdictionarySelect.each(function () {
//               var found = false;

//               for (var i = 0; i < result.response.keys.length; i++) {
//                 if ($(this).data("old_val") === result.response.keys[i]) {
//                   $(this).val($(this).data("old_val"));
//                   found = true;
//                 }
//               }

//               if (
//                 result.response.keys.length === 1 &&
//                 $(this).is("[required]")
//               ) {
//                 $(this).val(result.response.keys[0]);
//                 found = true;
//               }

//               if (!found) {
//                 this.selectedIndex = 0;
//               }
//             });

//             $subdictionarySelect.change();
//           },
//           function (err) {
//             $subdictionarySelect.change();
//           },
//           true
//         );
//       } else {
//         $subdictionarySelect.change();
//       }
//     };

//     $dictionarySelect.once("set-subdictionary-dependencies").change(change);
//     change();
//   },
// };

// window.getSubdictionariesByDictionaryId = function (
//   id,
//   context,
//   dictionary_model,
//   onSuccess,
//   onError
// ) {
//   libs.xhr.getJSON(
//     window.LOCALED_ROOT_URL +
//       "dictionary/get-subdictionaries-by-dictionary-id/" +
//       dictionary_model +
//       "/?main_dictionary_id=" +
//       id +
//       "&context=" +
//       context,
//     {},
//     onSuccess,
//     onError,
//     true
//   );
// };

/**
 * http://stackoverflow.com/questions/7718935/load-scripts-asynchronously
 */
// (function () {
//   let asyncScripts = {
//     load: function (src, callback) {
//       let s, r, t;
//       r = false;
//       s = document.createElement("script");
//       s.type = "text/javascript";
//       s.src = src;
//       s.onload = s.onreadystatechange = function () {
//         if (!r && (!this.readyState || this.readyState === "complete")) {
//           r = true;
//           if (callback) {
//             callback();
//           }
//         }
//       };
//       t = document.getElementsByTagName("script");
//       t = t[t.length - 1];
//       t.parentNode.insertBefore(s, t);
//     },
//   };

//   // Initialize lib namespace
//   if (window.libs === undefined) {
//     window.libs = {};
//   }

//   // Add to libs
//   window.libs.asyncScripts = asyncScripts;
// })();

/**
 * @author Gestycontrol
 */
// (function () {
//   let backend = {
//     _urlHandlers: {},
//     addUrlHandler: function (type, handler) {
//       backend._urlHandlers[type] = handler;
//     },
//     url: function (type, param1, param2, param3, param4, param5) {
//       return backend._urlHandlers[type](param1, param2, param3, param4, param5);
//     },
//   };

//   // Initialize lib namespace
//   if (window.libs === undefined) {
//     window.libs = {};
//   }

//   // Add to libs
//   window.libs.backend = backend;
// })();

/**
 * @author Gestycontrol
 */
// (function () {
//   let form = {
//     appendObjectToFormData: function (formData, varname, obj) {
//       let varValue = this.objToVarValue(obj, varname);

//       for (var key in varValue) {
//         formData.append(key, varValue[key]);
//       }
//     },
//     objToVarValue: function (obj, prefix) {
//       let varValue = {};

//       for (var key in obj) {
//         let subVarValues = {};

//         if (typeof obj[key] === "object") {
//           subVarValues = this.objToVarValue(obj[key], "[" + key + "]");
//         } else {
//           subVarValues["[" + key + "]"] = obj[key];
//         }

//         for (var sub in subVarValues) {
//           varValue[prefix + sub] = subVarValues[sub];
//         }
//       }

//       return varValue;
//     },
//     /**
//      * Retrieve data form contained inputs.
//      * @param {DOMObject} $container
//      * @param {FormData} form_data
//      */
//     getData: function ($container, form_data) {
//       $container = $($container);

//       let formData = new FormData();
//       let data = {};

//       $container.find("input, textarea, select").each(function () {
//         let name = $(this).attr("name");
//         let add_value = false;
//         let value = null;

//         if (!name) {
//           return;
//         }

//         if (
//           this.disabled ||
//           ($(this).is("input") &&
//             (this.type === "checkbox" || this.type === "radio") &&
//             !this.checked)
//         ) {
//           // ignore unchecked inputs and disabled elements
//         } else {
//           add_value = true;
//           value = $(this).val();
//         }

//         if (add_value) {
//           if (form_data) {
//             if ($(this).is("[type=file]")) {
//               formData.append(name, this.files[0]);
//             } else {
//               formData.append(name, value);
//             }
//           } else {
//             data = form._setVarValue(data, name, value);
//           }
//         }
//       });

//       if (form_data) {
//         return formData;
//       }

//       return data;
//     },
//     /**
//      * Recursive function to allow structured data retrieval
//      * @param {Object} data
//      * @param {Object} name
//      * @param {Object} value
//      */
//     _setVarValue: function (data, name, value) {
//       // Remove initial [
//       if (name.substring(0, 1) === "[") {
//         name = name.substring(1);
//       }

//       // Split name structure
//       let nameParts = name.split("[");

//       // Remove trailing ]
//       if (
//         nameParts[0].substring(nameParts[0].length - 1, nameParts[0].length) ===
//         "]"
//       ) {
//         nameParts[0] = nameParts[0].substring(0, nameParts[0].length - 1);
//       }

//       if (
//         nameParts.length === 1 ||
//         (nameParts.length === 2 &&
//           (nameParts[nameParts.length - 1] === "" ||
//             nameParts[nameParts.length - 1] === "]"))
//       ) {
//         // Check if it is and unindexed array
//         if (
//           name.length > 2 &&
//           name.substring(name.length - 2, name.length) === "[]"
//         ) {
//           // Initialize array
//           if (data[nameParts[0]] === undefined) {
//             data[nameParts[0]] = [];
//           }

//           // Push value
//           data[nameParts[0]].push(value);
//         } else {
//           // Single value, save
//           data[nameParts[0]] = value;
//         }
//       } else {
//         // Initialize hashmap
//         if (data[nameParts[0]] === undefined) {
//           data[nameParts[0]] = {};
//         }

//         // Alter structure
//         data[nameParts[0]] = form._setVarValue(
//           data[nameParts[0]],
//           name.substring(nameParts[0].length + 1),
//           value
//         );
//       }

//       return data;
//     },
//   };

//   // Initialize lib namespace
//   if (window.libs === undefined) {
//     window.libs = {};
//   }

//   // Add to libs
//   window.libs.form = form;
// })();

/**
 * @author Gestycontrol
 */
// (function () {
//   let lazy = {
//     init: function () {
//       this.setListeners();
//     },
//     setListeners: function () {
//       $("[data-lazy-content]")
//         .once("set-lazy-content-listener")
//         .each(function () {
//           $(this).on("inview", function (event, isInView) {
//             if (!isInView) {
//               return;
//             }

//             $(this)
//               .unbind("inview")
//               .once("load-lazy-content")
//               .each(function () {
//                 lazy._load($(this));
//               });
//           });
//         });

//       $("[data-lazy-content-refresh]")
//         .once("refresh-lazy-content")
//         .each(function () {
//           let $refleshElement = $(this);

//           $refleshElement.data("refreshLazyContent", function (force_load) {
//             if ($refleshElement.find(".inmoglaciar-loading")) {
//               $(".inmoglaciar-loading").show();
//             }

//             let $deleteElements = $refleshElement
//                 .parent()
//                 .find("[data-lazy-content-delete-on-refresh]"),
//               lazy_name = $refleshElement.data("lazy-content-refresh"),
//               lazy_params = $refleshElement.data("lazy-content-params"),
//               table_with_caption = $refleshElement.find("caption").length > 0,
//               panel_body_replace = $refleshElement.is(".panel"),
//               other_element = $refleshElement.hasClass("has-loading"),
//               $heightElement = $refleshElement.data(
//                 "lazy-content-fix-height-to"
//               )
//                 ? $($refleshElement.data("lazy-content-fix-height-to"))
//                 : null,
//               $html = null,
//               original_height = $refleshElement.get(0).offsetHeight;

//             $refleshElement.removeAttr("data-lazy-content-refresh");
//             $refleshElement.removeAttr("data-lazy-content");

//             switch (true) {
//               case table_with_caption:
//                 $html = $("<div>");
//                 $html.attr({
//                   "data-lazy-content": lazy_name,
//                   "data-lazy-content-replace": "true",
//                 });
//                 $html.data("lazy-content-params", lazy_params);
//                 $refleshElement.after($html).appendTo($html);
//                 $refleshElement.find("caption").show();
//                 break;

//               case other_element:
//                 $refleshElement.prepend(
//                   '<div class="loading ' +
//                     ($refleshElement.is(".is-pagination, .panel-body")
//                       ? "loading-full"
//                       : "loading-list") +
//                     '"><span class="loading-content"><span class="loading-info">' +
//                     ($refleshElement.is(
//                       ".is-pagination, .result-name, .panel-body"
//                     )
//                       ? ""
//                       : '<svg class="circular-spinner" viewBox="25 25 50 50"><circle class="path-spinner" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/></svg>') +
//                     "</span></span></div>"
//                 );

//                 $html = $("<div>");
//                 $html.attr({
//                   "data-lazy-content": lazy_name,
//                   "data-lazy-content-replace": "true",
//                 });
//                 $html.data("lazy-content-params", lazy_params);
//                 $refleshElement.after($html).appendTo($html);
//                 break;

//               case panel_body_replace:
//                 $html = $(
//                   '<div class="panel"><div class="panel-body empty-loading"><div class="loading loading-full loading-panel"><span class="loading-content"><span class="loading-info"><svg class="circular-spinner" viewBox="25 25 50 50"><circle class="path-spinner" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/></svg></span></span></div></div></div>'
//                 );
//                 $html.attr({
//                   "data-lazy-content": lazy_name,
//                   "data-lazy-content-replace": "true",
//                 });
//                 $html.data("lazy-content-params", lazy_params);
//                 $html.css({ height: $refleshElement.get(0).offsetHeight });
//                 $refleshElement.after($html).remove();
//                 break;
//             }

//             // Set height if required
//             if ($heightElement && $heightElement.length) {
//               let height = 0;

//               $heightElement.filter(":visible").each(function () {
//                 if (this.offsetHeight > height) {
//                   height = this.offsetHeight;
//                 }
//               });

//               if (height > original_height) {
//                 $html.css({ height: height });
//               }
//             }

//             $deleteElements.remove();
//             libs.lazy.init();

//             if (force_load) {
//               libs.lazy.forceLoad([$html.get(0)]);
//             }
//           });
//         });
//     },
//     forceLoad: function (names, $container) {
//       let $elements = [];

//       if (typeof names == "object") {
//         $elements = $($.unique($(names).get()));
//       } else {
//         if (!$container) {
//           $container = $("body");
//         }

//         if (typeof names == "string") {
//           names = names.split(",");
//         }

//         for (var i = 0; i < names.length; i++) {
//           let name = names[i];

//           if (name) {
//             $container
//               .find('[data-lazy-content="' + name + '"]')
//               .each(function () {
//                 $elements.push(this);
//               });
//           }
//         }

//         $elements = $($.unique($elements));
//       }

//       $elements.each(function () {
//         $(this)
//           .unbind("inview")
//           .once("load-lazy-content")
//           .each(function () {
//             lazy._load($(this));
//           });
//       });
//     },
//     refresh: function (names, $container, force_load) {
//       let $elements = [];

//       if (typeof names == "object") {
//         $elements = $($.unique($(names).get()));
//       } else {
//         if (!$container) {
//           $container = $("body");
//         }

//         if (typeof names == "string") {
//           names = names.split(",");
//         }

//         for (var i = 0; i < names.length; i++) {
//           let name = names[i];

//           if (name) {
//             $container
//               .find('[data-lazy-content-refresh="' + name + '"]')
//               .each(function () {
//                 $elements.push(this);
//               });
//           }
//         }

//         $elements = $($.unique($elements));
//       }

//       $elements.each(function () {
//         let refreshLazyContentFunction = $(this).data("refreshLazyContent");

//         if (refreshLazyContentFunction) {
//           refreshLazyContentFunction(force_load);
//         }
//       });
//     },
//     _load: function ($container) {
//       let lazy_name = $container.data("lazy-content"),
//         lazy_params = $container.data("lazy-content-params"),
//         lazy_replace = !!$container.data("lazy-content-replace");

//       libs.backend.content.getLazy(
//         lazy_name,
//         lazy_params,
//         function (result) {
//           let $replacedElement = null;

//           if (lazy_replace) {
//             $replacedElement = $container;
//           } else {
//             $replacedElement = $container.children(".panel-body.empty-loading");
//           }

//           // Replace content
//           let $html = $("<div>")
//             .html(
//               result.response.html
//                 .split("RANDOMAJAXID")
//                 .join(libs.randomizer.id())
//             )
//             .children();

//           $replacedElement.after($html).remove();

//           // Callback (load)
//           if (lazy.afterLoad !== undefined) {
//             lazy.afterLoad(lazy_name, lazy_params, lazy_replace, $html);
//           }

//           // Callback (visible)
//           if (lazy.afterVisible) {
//             $html.on("inview", function (event, isInView) {
//               if (!isInView) {
//                 return;
//               } else {
//                 $html.unbind("inview");
//               }

//               lazy.afterVisible(lazy_name, lazy_params, lazy_replace, $html);
//             });
//           }
//         },
//         libs.lazy.showError,
//         true
//       );

//       $container.removeAttr("data-lazy-content-params");
//       $container.removeAttr("data-lazy-content");
//     },
//   };

//   $(document).ready(function () {
//     lazy.init();
//   });

//   // Initialize lib namespace
//   if (window.libs === undefined) {
//     window.libs = {};
//   }

//   // Add to libs
//   window.libs.lazy = lazy;

//   lazy.afterLoad = function (lazy_name, lazy_params, lazy_replace, $html) {
//     $(document).foundation();
//     window.forms.init();
//     addons.pagination();
//     lazy.init();
//   };

//   lazy.afterVisible = function (lazy_name, lazy_params, lazy_replace, $html) {
//     window.libs.modal.init();
//     addons.init();
//   };
// })();

/**
 * @author Gestycontrol
 */
// (function () {
//   var modal = {
//     init: function () {
//       this.setListeners();
//     },
//     setListeners: function () {
//       $(document)
//         .once("modal-open")
//         .on("click", "[data-modal-name]", function (e) {
//           modal.show(
//             this,
//             $(this).data("modal-name"),
//             $(this).data("modal-params")
//           );
//         });
//     },
//     show: function (target, modal_name, modal_params, loaded, visible) {
//       $("body")
//         .addClass("has-loading")
//         .prepend(
//           '<div class="loading loading-full loading-full-page"><span class="loading-content"><span class="loading-info"></span></span></div>'
//         )
//         .css({ height: $("body").get(0).scrollHeight });

//       // Load extra information
//       modal._beforeShow(
//         target,
//         modal_name,
//         modal_params,
//         function (show_modal, data) {
//           if (show_modal) {
//             // Load modal from backend
//             window.libs.backend.content.getModal(
//               modal_name,
//               modal_params,
//               data,
//               function (result) {
//                 if (!result.response.html) {
//                   result.response.html = "";
//                 }

//                 // Parse HTML
//                 var $html = $("<div>");
//                 $html.append(
//                   result.response.html
//                     .split("RANDOMAJAXID")
//                     .join(libs.randomizer.id())
//                 );
//                 $html = $html.children();
//                 $html.data("libs-modal-loaded-modal-name", modal_name);

//                 // Add to body
//                 $("body").append($html);

//                 // Callback (load)
//                 if (modal.afterLoad !== undefined) {
//                   modal.afterLoad(modal_name, modal_params, data, $html);
//                 }

//                 if (loaded) {
//                   loaded($html);
//                 }

//                 // Show modal
//                 modal._show($html);

//                 // Callback (visible)
//                 if (modal.afterVisible || visible) {
//                   $html.on("inview", function (event, isInView) {
//                     if (!isInView) {
//                       return;
//                     } else {
//                       $html.unbind("inview");
//                     }

//                     if (modal.afterVisible) {
//                       modal.afterVisible(modal_name, modal_params, data, $html);
//                     }

//                     if (visible) {
//                       visible($html);
//                     }
//                   });
//                 }

//                 if ($("body.has-loading")) {
//                   $("body")
//                     .removeClass("has-loading")
//                     .children("div.loading-full")
//                     .remove();
//                   $("body").attr("style", "");
//                 }
//               },
//               function () {
//                 $("body")
//                   .removeClass("has-loading")
//                   .children("div.loading-full")
//                   .remove();
//                 $("body").attr("style", "");

//                 libs.modal.showError();
//               },
//               true
//             );
//           }
//         }
//       );
//     },
//     _show: function ($html) {
//       var popup = new Foundation.Reveal($html);
//       popup.open();

//       if ($html.is("[data-layer]")) {
//         if (!window.dataLayer) {
//           window.dataLayer = [];
//         }

//         window.dataLayer.push($html.data("layer"));
//       }

//       $html.on("closed.zf.reveal", function () {
//         if (modal.afterClose) {
//           modal.afterClose($html.data("libs-modal-loaded-modal-name"));
//         }

//         $html.parent().remove();
//       });

//       modal._lastShownElement = $html;
//     },
//     _close: function ($html) {
//       $html.find("[data-close]").trigger("click");
//     },
//     _lastShownElement: null,
//     close: function () {
//       modal._close(modal._lastShownElement);
//     },
//     showError: function (err) {
//       if (!err) {
//         err = "Error inesperado.";
//       }

//       console.log(err);
//     },
//     _beforeShowHandlers: {},
//     _beforeShow: function (target, modal_name, modal_params, callback) {
//       if (modal._beforeShowHandlers[modal_name] === undefined) {
//         callback(true, {});
//       } else {
//         modal._beforeShowHandlers[modal_name](
//           target,
//           modal_name,
//           modal_params,
//           callback
//         );
//       }
//     },
//     addBeforeShowHandler: function (modal_name, watcherFunction) {
//       modal._beforeShowHandlers[modal_name] = watcherFunction;
//     },
//   };

//   $(document).ready(function () {
//     modal.setListeners();
//   });

//   // Initialize lib namespace
//   if (window.libs === undefined) {
//     window.libs = {};
//   }

//   // Add to libs
//   window.libs.modal = modal;

//   modal.afterLoad = function (lazy_name, lazy_params, lazy_replace, $html) {
//     $(document).foundation();
//     window.forms.init();
//     addons.pagination();
//     window.libs.lazy.init();
//   };

//   modal.afterVisible = function (lazy_name, lazy_params, lazy_replace, $html) {
//     window.libs.modal.init();
//     addons.init();
//   };
// })();

/**
 * http://stackoverflow.com/questions/7718935/load-scripts-asynchronously
 */
// (function () {
//   let randomizer = {
//     id: function () {
//       return this._time() + this._randomDecimalsToString();
//     },
//     _randomDecimalsToString: function () {
//       return Math.random().toString(36).substring(7);
//     },
//     _time: function () {
//       return Math.floor(new Date().getTime() / 1000);
//     },
//   };

//   // Initialize lib namespace
//   if (window.libs === undefined) {
//     window.libs = {};
//   }

//   // Add to libs
//   window.libs.randomizer = randomizer;
// })();

/**
 * @author Gestycontrol
 */
// (function () {
//   let from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
//     to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
//     mapping = {};

//   for (var i = 0, j = from.length; i < j; i++)
//     mapping[from.charAt(i)] = to.charAt(i);

//   let text = {
//     normalize: function (str) {
//       let ret = [];
//       for (var i = 0, j = str.length; i < j; i++) {
//         let c = str.charAt(i);
//         if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
//         else ret.push(c);
//       }
//       return ret.join("");
//     },
//     starts_with: function (haystack, needle) {
//       return haystack.startsWith(needle);
//     },
//     starts_with_normalized: function (haystack, needle) {
//       return text.starts_with(haystack, needle);
//     },
//     istarts_with_normalized: function (haystack, needle) {
//       return text.starts_with_normalized(
//         haystack.toLowerCase(),
//         needle.toLowerCase()
//       );
//     },
//     istarts_with_normalized_no_spaces: function (haystack, needle) {
//       return text.istarts_with_normalized(
//         haystack.split(" ").join(""),
//         needle.split(" ").join("")
//       );
//     },
//   };

//   // Initialize lib namespace
//   if (window.libs === undefined) {
//     window.libs = {};
//   }

//   // Add to libs
//   window.libs.text = text;
// })();

/**
 * @author Gestycontrol
 */
// (function () {
//   let xhr = {
//     getJSON: function (
//       url,
//       data,
//       success,
//       error,
//       call_error_on_succeeded_false
//     ) {
//       // Dummy services
//       if (xhr._dummyUrl(url, success, error, call_error_on_succeeded_false)) {
//         return;
//       }

//       $.ajax({
//         method: "GET",
//         dataType: "json",
//         url: url,
//         data: data,
//         success: function (result) {
//           xhr._success(result, success, error, call_error_on_succeeded_false);
//         },
//         error: error,
//       });
//     },
//     postJSON: function (
//       url,
//       data,
//       success,
//       error,
//       call_error_on_succeeded_false
//     ) {
//       // Dummy services
//       if (xhr._dummyUrl(url, success, error, call_error_on_succeeded_false)) {
//         return;
//       }

//       $.ajax({
//         method: "POST",
//         dataType: "json",
//         url: url,
//         data: data,
//         success: function (result) {
//           xhr._success(result, success, error, call_error_on_succeeded_false);
//         },
//         error: error,
//       });
//     },
//     postMultipartJSON: function (
//       url,
//       data,
//       success,
//       error,
//       call_error_on_succeeded_false
//     ) {
//       // Dummy services
//       if (xhr._dummyUrl(url, success, error, call_error_on_succeeded_false)) {
//         return;
//       }

//       $.ajax({
//         method: "POST",
//         dataType: "json",
//         url: url,
//         data: data,
//         success: function (result) {
//           xhr._success(result, success, error, call_error_on_succeeded_false);
//         },
//         error: error,
//         processData: false,
//         contentType: false,
//       });
//     },
//     _success: function (result, success, error, call_error_on_succeeded_false) {
//       if (
//         typeof result !== undefined &&
//         typeof result.fatal_error !== "undefined" &&
//         result.fatal_error
//       ) {
//         location.reload();
//       } else {
//         if (
//           call_error_on_succeeded_false &&
//           (!result || !result.response || !result.response.succeeded)
//         ) {
//           if (error) {
//             error(result ? result.error : null);
//           }

//           return;
//         }

//         if (success) {
//           success(result);
//         }
//       }
//     },
//     _dummyUrl: function (url, success, error, call_error_on_succeeded_false) {
//       if (url.dummy) {
//         if (url.file) {
//           $.get(url.file, function (content) {
//             if (url.format === "html") {
//               xhr._success(
//                 {
//                   response: { html: content, succeeded: true },
//                   error: null,
//                 },
//                 success,
//                 error,
//                 call_error_on_succeeded_false
//               );
//             } else {
//               xhr._success(
//                 content,
//                 success,
//                 error,
//                 call_error_on_succeeded_false
//               );
//             }
//           });
//         } else if (url.result) {
//           setTimeout(function () {
//             success(url.result);
//           }, 100);
//         }
//       }

//       return url.dummy;
//     },
//   };

//   // Initialize lib namespace
//   if (window.libs === undefined) {
//     window.libs = {};
//   }

//   // Add to libs
//   window.libs.xhr = xhr;
// })();

window.number_format = function (number, decimal_pos) {
  if (number === null || number.toString().length === 0) {
    return "";
  }

  let decimal_sep, thousand_sep;

  if (window.LANG === "en") {
    decimal_sep = ".";
    thousand_sep = ",";
  } else {
    decimal_sep = ",";
    thousand_sep = ".";
  }

  let ts = thousand_sep,
    ds = decimal_sep,
    dp = decimal_pos,
    n = Math.abs(Math.ceil(number)).toString(),
    i = n.length % 3,
    f = (number < 0 ? "-" : "") + n.substr(0, i);

  for (; i < n.length; i += 3) {
    if (i !== 0) f += ts;
    f += n.substr(i, 3);
  }

  if (dp > 0) {
    f += ds + number.toFixed(dp).split(".")[1];
  }

  return f;
};

window.updateSliderForAreaControl = function (id, options) {
  let $slider = jQuery('[aria-controls="' + id + '"]').parent();
  $slider.foundation("_destroy");

  for (let opt in options) {
    $slider.data(opt, options[opt]);
  }

  if (options.end) {
    $slider
      .siblings(".slider-values")
      .find("[data-slider-end-formatted]")
      .text(window.number_format(options.end, 0));
  }

  new Foundation.Slider($slider);
};

// window.resetButton = {
//   init: function ($form) {
//     window.resetButton._setListeners($form);
//   },
//   _setListeners: function ($form) {
//     $form
//       .find('[type="reset"]')
//       .once("reset")
//       .click(function (e) {
//         e.preventDefault();
//         e.stopPropagation();

//         $form.find('input:not([type="submit"], [type="button"])').val("");
//         $form.find("select").each(function () {
//           this.selectedIndex = 0;
//         });

//         $form.submit();
//       });
//   },
// };

// window.forms = {
//   init: function () {
//     this.setListeners();
//   },
//   setListeners: function () {
//     let self = this;
//     $(".check-client-detail-form").click(function () {
//       let form = $(this).closest("form");
//     });

//     $(".tr-resume")
//       .once("set-resume-tr")
//       .each(function () {
//         $(this).on("click", function (e) {
//           e.preventDefault();
//           var theTrigger = $(this);
//           theTrigger.toggleClass("is-expanded");
//           theTrigger.next(".tr-detail").toggleClass("is-expanded");

//           window.viewstate["trResume"] = {};

//           $(".tr-detail.is-expanded").each(function () {
//             let tr_resume_id = $(this)
//               .parent()
//               .children()
//               .eq($(this).index() - 1)
//               .attr("id");

//             if (!tr_resume_id) {
//               return;
//             }

//             window.viewstate["trResume"][tr_resume_id] = true;
//             localStorage.setItem("viewstate", JSON.stringify(window.viewstate));
//           });
//         });
//       });

//     for (var tabpanel in viewstate.tabs) {
//       if (!tabpanel) {
//         continue;
//       }

//       window.jumpToTab(viewstate.tabs[tabpanel].active);
//     }

//     for (var accordion in viewstate.accordions) {
//       if (!accordion) {
//         continue;
//       }

//       window.jumpToAccordion(accordion, viewstate.accordions[accordion].active);
//     }

//     for (var tr_resume_id in viewstate.trResume) {
//       if (!tr_resume_id) {
//         continue;
//       }

//       if (
//         !$('[id="' + tr_resume_id + '"]')
//           .next(".tr-detail")
//           .is(".is-expanded")
//       ) {
//         $('[id="' + tr_resume_id + '"]').click();
//       }
//     }

//     $("form").each(function () {
//       var $form = $(this);

//       $form
//         .find(".btn-submit-form")
//         .once("set-submit-button")
//         .click(function (e) {
//           e.preventDefault();
//           e.stopPropagation();

//           $form.submit();
//         });

//       window.resetButton.init($form);
//       window.subdictionarySelect.init($form);

//       if ($form.find("[data-on-change-fill-from-client]").length > 0) {
//         window.fillFromClient.init($form);
//       }

//       $form.find("input.clickable[name]").removeAttr("name");

//       $form.once("set-submit-event").submit(function (e) {
//         if ($form.is('[action="javascript:void(0)"]')) {
//           return;
//         }

//         var $hiddenElements = $(this).find(
//           'input[type="submit"], .btn-submit-form, [data-hide-on-submit]'
//         );
//         $hiddenElements.hide();

//         if ($form.parents(".reveal, .ajax-form-container").length > 0) {
//           e.preventDefault();
//           e.stopPropagation();

//           libs.backend.sendModalForm(
//             $form,
//             function (result) {
//               $hiddenElements.show();

//               if ($form.get(0).onSubmitSuccess) {
//                 $form.get(0).onSubmitSuccess(result);
//               } else {
//                 if ($form.data("force-lazy-content-refresh")) {
//                   libs.lazy.refresh($form.data("force-lazy-content-refresh"));
//                 }
//                 if ($form.data("open-modal-on-success")) {
//                   libs.modal.show(
//                     null,
//                     $form.data("open-modal-on-success"),
//                     result.response
//                   );
//                 }
//                 if ($form.data("redirect-to-on-success")) {
//                   location.href =
//                     $form.data("redirect-to-on-success") +
//                     "?" +
//                     $.param(result.response);
//                 }
//                 if ($form.data("reload-on-success")) {
//                   location.reload();
//                 }
//               }

//               if ($form.parents(".reveal").length > 0) {
//                 libs.modal._lastShownElement = $form.parents(".reveal").eq(0);
//                 libs.modal.close();
//               }
//             },
//             function () {
//               $hiddenElements.show();
//               try {
//                 grecaptcha.reset();
//               } catch (e) {}
//             }
//           );
//         }

//         if ($form.is("[full-page-loading-on-submit]")) {
//           $("body")
//             .addClass("has-loading")
//             .prepend(
//               '<div class="loading loading-full loading-full-page"><span class="loading-content"><span class="loading-info"></span></span></div>'
//             )
//             .css({ height: $("body").get(0).scrollHeight });
//         }
//       });
//     });
//   },
// };

// (function () {
//   // Shortcuts
//   let libs = window.libs;
//   let backend = libs.backend;
//   let xhr = libs.xhr;

//   backend.content = {
//     getModal: function (
//       name,
//       params,
//       data,
//       success,
//       error,
//       call_error_on_succeeded_false
//     ) {
//       xhr.postJSON(
//         backend.url("content/modal", name),
//         {
//           params: params,
//           data: data,
//         },
//         success,
//         error,
//         call_error_on_succeeded_false
//       );
//     },
//     getLazy: function (
//       name,
//       params,
//       success,
//       error,
//       call_error_on_succeeded_false
//     ) {
//       xhr.getJSON(
//         backend.url("content/lazy", name),
//         params,
//         success,
//         error,
//         call_error_on_succeeded_false
//       );
//     },
//   };

//   backend.sendAjaxForm = function ($form, success, error) {
//     backend.sendForm(
//       $form,
//       success,
//       function (err) {
//         let errHtml =
//           '<h2 style="color: red; font-weight: bold;" class="inmoglaciar-ajax-form-error">';
//         errHtml += err ? err : "Error inesperado.";
//         errHtml += "</h2>";

//         $form.find(".inmoglaciar-ajax-form-error").remove();
//         $form.find(".inmoglaciar-before-form-errors").eq(0).after(errHtml);

//         if (error) {
//           error();
//         }
//       },
//       true
//     );
//   };

//   backend.sendModalForm = function ($form, success, onError) {
//     backend.sendForm(
//       $form,
//       success,
//       function (err) {
//         var errHtml = '<div class="reveal__errors callout alert"><p>';
//         errHtml += err ? err : "Error inesperado.";
//         errHtml += "</p></div>";

//         $form.find(".reveal__errors").remove();

//         if ($form.find(".reveal__header").length > 0) {
//           $form.find(".reveal__header").after(errHtml);
//         } else {
//           $form.prepend(errHtml);
//         }

//         $form.parents(".reveal-overlay").scrollTop(0);

//         if (onError) {
//           onError();
//         }
//       },
//       true
//     );
//   };

//   backend.sendForm = function (
//     $form,
//     success,
//     error,
//     call_error_on_succeeded_false
//   ) {
//     let url = $form.attr("action");
//     if (!url || url === "javascript:void(0)") {
//       url = {
//         dummy: true,
//         result: {
//           response: {
//             succeeded: true,
//           },
//           error: null,
//         },
//       };
//     }

//     xhr.postMultipartJSON(
//       url,
//       libs.form.getData($form, true),
//       function (result) {
//         $form.find(".form-error").remove();

//         if (!result || !result.response || !result.response.succeeded) {
//           if (result.errorsByField) {
//             let $formInputs = $form.find("select, input, textarea");

//             for (var model in result.errorsByField) {
//               for (var field in result.errorsByField[model]) {
//                 let errorFields = $formInputs
//                   .filter(
//                     '[name*="[' +
//                       model +
//                       "][" +
//                       field +
//                       ']"], [data-name*="[' +
//                       model +
//                       "][" +
//                       field +
//                       ']"]'
//                   )
//                   .toArray();
//                 $formInputs
//                   .filter('[name*="[' + field + ']"]')
//                   .each(function () {
//                     if ($.inArray(this, errorFields) === -1) {
//                       if (
//                         $(this)
//                           .attr("name")
//                           .split("[" + model + "][Translation]").length > 1
//                       ) {
//                         errorFields.push(this);
//                       }
//                     }
//                   });
//                 $formInputs
//                   .filter('[data-name*="[' + field + ']"]')
//                   .each(function () {
//                     if ($.inArray(this, errorFields) === -1) {
//                       if (
//                         $(this)
//                           .attr("data-name")
//                           .split("[" + model + "][Translation]").length > 1
//                       ) {
//                         errorFields.push(this);
//                       }
//                     }
//                   });

//                 jQuery.each(errorFields, function () {
//                   let $afterElement = $(this);

//                   if ($afterElement.data("select2")) {
//                     $afterElement = $afterElement.data("select2").$container;
//                   }

//                   if ($afterElement.parent().is(".input-group")) {
//                     $afterElement = $afterElement.parent();
//                   }
//                   if ($afterElement.is(".rich-editor")) {
//                     $afterElement = $afterElement.parent();
//                   }

//                   $afterElement.after(
//                     '<span class="form-error is-visible">' +
//                       result.errorsByField[model][field] +
//                       "</span>"
//                   );
//                 });
//               }
//             }

//             $form
//               .find(".inmoglaciar-toggle:not(.inmoglaciar-toggle-opened)")
//               .find(".form-error.is-visible")
//               .parents(".inmoglaciar-toggle:not(.inmoglaciar-toggle-opened)")
//               .addClass("inmoglaciar-toggle-opened");
//           }

//           if (call_error_on_succeeded_false) {
//             if (error) {
//               error(result.error);
//             }
//           }
//         } else {
//           success(result);
//         }
//       },
//       error,
//       false
//     );
//   };

//   backend.geo = {
//     getCitiesByProvinceId: function (
//       province_id,
//       success,
//       error,
//       call_error_on_succeeded_false
//     ) {
//       xhr.getJSON(
//         backend.url("geo/getCitiesByProvinceId", province_id),
//         {
//           province_id: province_id,
//         },
//         success,
//         error,
//         call_error_on_succeeded_false
//       );
//     },
//     getSubcitiesByCityId: function (
//       city_id,
//       success,
//       error,
//       call_error_on_succeeded_false
//     ) {
//       xhr.getJSON(
//         backend.url("geo/getSubcitiesByCityId", city_id),
//         {
//           city_id: city_id,
//         },
//         success,
//         error,
//         call_error_on_succeeded_false
//       );
//     },
//     getDistrictsByCityId: function (
//       city_id,
//       success,
//       error,
//       call_error_on_succeeded_false
//     ) {
//       xhr.getJSON(
//         backend.url("geo/getDistrictsByCityId", city_id),
//         {
//           city_id: city_id,
//         },
//         success,
//         error,
//         call_error_on_succeeded_false
//       );
//     },
//   };
// })();

// (function () {
//   // Shortcuts
//   let libs = window.libs;
//   let backend = libs.backend;

//   // Content URLs
//   backend.addUrlHandler("content/modal", function (modal_name) {
//     if (window.IS_TEMPLATE) {
//       return {
//         dummy: true,
//         file: "/public-modals/" + modal_name + ".html",
//         format: "html",
//       };
//     } else {
//       return window.LOCALED_ROOT_URL + "public-modals/" + modal_name + "/";
//     }
//   });

//   backend.addUrlHandler("content/lazy", function (name) {
//     if (window.IS_TEMPLATE) {
//       return {
//         dummy: true,
//         file: "/public-lazy-content/" + name + ".html",
//         format: "html",
//       };
//     } else {
//       return window.LOCALED_ROOT_URL + "public-lazy-content/" + name + "/";
//     }
//   });

//   // GEO URLs
//   backend.addUrlHandler("geo/getCitiesByProvinceId", function (province_id) {
//     if (window.IS_TEMPLATE) {
//       let file;
//       province_id = parseInt(province_id);

//       if (province_id === 1 || province_id === 2) {
//         file = "/dist/static/_backend/geo/cities-" + province_id + ".json";
//       } else {
//         file = "/dist/static/_backend/geo/cities-other.json";
//       }

//       return {
//         dummy: true,
//         file: file,
//         format: "json",
//       };
//     } else {
//       return (
//         window.LOCALED_ROOT_URL +
//         "geo/cities-by-province-id/" +
//         province_id +
//         "/"
//       );
//     }
//   });
// })();

$("#promotionHeroVideo").YTPlayer({
  fitToBackground: true,
  video_youtube_identifier: "Qh02qsOAo_8",
  playerVars: {
    modestbranding: 0,
    autoplay: 1,
    controls: 1,
    showinfo: 0,
    branding: 0,
    rel: 0,
    autohide: 0,
    start: 59,
  },
});

var swiper = new Swiper(".swiper-container.hero-slider", {
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

//change-header
$(function () {
  var $win = $(window);
  var $pos = 140;
  $win.scroll(function () {
    if ($win.scrollTop() <= $pos) $(".fixed-header").removeClass("is-scrolled");
    else {
      $(".fixed-header").addClass("is-scrolled");
    }
  });
});

//to pages for advice
$(function () {
  var $win = $(window);
  var $pos = 140;
  $win.scroll(function () {
    if ($win.scrollTop() <= $pos) $("body").removeClass("is-scrolled");
    else {
      $("body").addClass("is-scrolled");
    }
  });
});

//promotion-header
$(function () {
  var $win = $(window);
  var $pos = 250;
  $win.scroll(function () {
    if ($win.scrollTop() <= $pos)
      $(".promotion-header").removeClass("is-scrolled");
    else {
      $(".promotion-header").addClass("is-scrolled");
    }
  });
});

//key-map
$("#example-vert-tabs").on("change.zf.tabs", function () {
  var $customDrop = $(".custom-drop"),
    $currentTab = $(".custom-drop").parent().find(".is-active").text(),
    $labelCustomDrop = $(".special-menu-button > span");

  $customDrop.foundation("toggleMenu");
  $labelCustomDrop.text($currentTab);
});

//timeline sliders & tabs

var swiper_1_settings = {
  // simulateTouch: false,
  preloadImages: false,
  lazy: false,
  navigation: {
    nextEl: ".swiper-button-next.swiper-button-next--0",
    prevEl: ".swiper-button-prev.swiper-button-prev--0",
  },
};

var swiper_2_settings = {
  // simulateTouch: false,
  preloadImages: false,
  lazy: false,
  navigation: {
    nextEl: ".swiper-button-next.swiper-button-next--1",
    prevEl: ".swiper-button-prev.swiper-button-prev--1",
  },
};

var swiper_3_settings = {
  // simulateTouch: false,
  preloadImages: false,
  lazy: false,
  navigation: {
    nextEl: ".swiper-button-next.swiper-button-next--2",
    prevEl: ".swiper-button-prev.swiper-button-prev--2",
  },
};

var swiper_4_settings = {
  // simulateTouch: false,
  preloadImages: false,
  lazy: false,
  navigation: {
    nextEl: ".swiper-button-next.swiper-button-next--3",
    prevEl: ".swiper-button-prev.swiper-button-prev--3",
  },
};

var swiper_5_settings = {
  // simulateTouch: false,
  preloadImages: false,
  lazy: false,
  navigation: {
    nextEl: ".swiper-button-next.swiper-button-next--4",
    prevEl: ".swiper-button-prev.swiper-button-prev--4",
  },
};

var swiper_6_settings = {
  // simulateTouch: false,
  preloadImages: false,
  lazy: false,
  navigation: {
    nextEl: ".swiper-button-next.swiper-button-next--5",
    prevEl: ".swiper-button-prev.swiper-button-prev--5",
  },
};

var swiper_1 = new Swiper(
  ".swiper-container.timeline-slider--0",
  swiper_1_settings
);
var swiper_2 = new Swiper(
  ".swiper-container.timeline-slider--1",
  swiper_2_settings
);
var swiper_3 = new Swiper(
  ".swiper-container.timeline-slider--2",
  swiper_3_settings
);
var swiper_4 = new Swiper(
  ".swiper-container.timeline-slider--3",
  swiper_4_settings
);
var swiper_5 = new Swiper(
  ".swiper-container.timeline-slider--4",
  swiper_5_settings
);
var swiper_6 = new Swiper(
  ".swiper-container.timeline-slider--5",
  swiper_6_settings
);

$("#timelinePromotion").on("change.zf.tabs down.zf.accordion", function () {
  swiper_1 = new Swiper(
    ".swiper-container.timeline-slider--0",
    swiper_1_settings
  );
  swiper_2 = new Swiper(
    ".swiper-container.timeline-slider--1",
    swiper_2_settings
  );
  swiper_3 = new Swiper(
    ".swiper-container.timeline-slider--2",
    swiper_3_settings
  );
  swiper_4 = new Swiper(
    ".swiper-container.timeline-slider--3",
    swiper_4_settings
  );
  swiper_5 = new Swiper(
    ".swiper-container.timeline-slider--4",
    swiper_5_settings
  );
  swiper_6 = new Swiper(
    ".swiper-container.timeline-slider--5",
    swiper_6_settings
  );
  window.dispatchEvent(new Event("resize"));
});

// OverlayScrollbars
document.addEventListener("DOMContentLoaded", function () {
  //The first argument are the elements to which the plugin shall be initialized
  //The second argument has to be at least a empty object or a object with your desired options
  OverlayScrollbars(document.querySelectorAll(".scroll-content"), {});
  OverlayScrollbars(document.querySelectorAll(".scroll-content-vertical"), {});
});

//share
var shareList = $(".share-promotion");
var shareSocialTrigger = $(".share-promotion-wrapper");

if (shareList) {
  shareSocialTrigger.on("click", function () {
    event.stopPropagation();
    shareList.fadeIn(200);
  });

  shareList.on("mouseleave", function () {
    setTimeout(function () {
      shareList.fadeOut(600);
    }, 200);
  });

  $(window).click(function () {
    shareList.fadeOut(200);
  });
}

// $(document).on("click", ".gdpr-link", function () {
//   let gdprFirstLayer = $(this)
//     .parents(".acceptance")
//     .siblings(".gdpr-first-layer");
//   gdprFirstLayer.toggleClass("is-visible");
//   OverlayScrollbars(document.querySelectorAll(".scroll-content-vertical"), {});
// });

// $(document).on("change", '.acceptance input[type="checkbox"]', function () {
//   $(".inmoglaciar-recaptcha").addClass("is-visible");
// });

// window.calculateMortgage = function (amount, years, annual_interest) {
//   let getFixedValue = function (amount, interest, payments) {
//     return (
//       amount *
//       ((interest * Math.pow(1 + interest, payments)) /
//         (Math.pow(1 + interest, payments) - 1))
//     );
//   };

//   let payments = years * 12;

//   if (!amount || !payments || !annual_interest || payments < 1) {
//     return;
//   }

//   return getFixedValue(amount, annual_interest / 12 / 100, payments);
// };

(function ($) {
  $(".swipebox").swipebox();
  // window.forms.init();
  addons.init();

  $(document).on("change", "#mortgageAssetPrice", function () {
    let max = parseInt($(this).val());

    if (isNaN(max)) {
      max = 1000000;
    }

    window.updateSliderForAreaControl("mortgageAmount", { end: max });
  });

  let updateMortgage = function () {
    $("#mortgageout").val(
      number_format(
        window.calculateMortgage(
          parseInt($("#mortgageAmount").val()),
          parseInt($("#mortgageDuration").val()),
          parseInt($("#mortgagepercentage").val())
        ),
        0
      ) + "€/mes"
    );
  };

  $(document).on(
    "change",
    "#mortgageAmount, #mortgagepercentage, #mortgageDuration",
    function () {
      updateMortgage();
    }
  );

  $(document).on("moved.zf.slider", ".slider", function () {
    updateMortgage();
  });
})(jQuery);

// (function () {
//   let CookieAlert = {
//     privacy_policy_url: "",
//     cookies_notice_url: "",
//     ignoreUrlPatterns: [],
//     message_html: "",
//     message_styles: "",
//     scripts: [],
//     addInlineScript: function (content) {
//       let $script = jQuery('<script type="text/javascript">');
//       $script[0].text = content;
//       $script.appendTo("body");
//     },
//     addInlineNoScript: function (content) {
//       let $noscript = jQuery("<noscript>");
//       $noscript[0].innerHTML = content;
//       $noscript.appendTo("body");
//     },
//     addExternalScript: function (src, async) {
//       let $script = jQuery('<script type="text/javascript">');
//       $script.attr("src", src);

//       if (async) {
//         $script.attr("async", async);
//       }

//       $script.appendTo("body");
//     },
//     init: function () {
//       if (!CookieAlert.messageHidden()) {
//         let showAlert = true;

//         for (let i = 0; i < CookieAlert.ignoreUrlPatterns.length; i++) {
//           if (
//             (location.href + "").split(CookieAlert.ignoreUrlPatterns[i])
//               .length > 1
//           ) {
//             showAlert = false;
//           }
//         }

//         // Page is not a legal notice
//         if (showAlert) {
//           if (
//             typeof document.referrer != "undefined" &&
//             document.referrer.split(location.hostname).length > 1
//           ) {
//             // User has been navigating the website
//             let comes_from_legal_notice = false;
//             for (let i = 0; i < CookieAlert.ignoreUrlPatterns.length; i++) {
//               if (
//                 document.referrer.split(CookieAlert.ignoreUrlPatterns[i])
//                   .length > 1
//               ) {
//                 comes_from_legal_notice = true;
//               }
//             }

//             if (!comes_from_legal_notice) {
//               CookieAlert.enableCookies();
//             }
//           }
//         }

//         CookieAlert.showAlert();
//       }

//       if (CookieAlert.cookiesEnabled()) {
//         for (let i = 0; i < CookieAlert.scripts.length; i++) {
//           let script = CookieAlert.scripts[i];

//           if (script.type === "inline") {
//             CookieAlert.addInlineScript(script.content);
//           } else if (script.type === "noscript") {
//             CookieAlert.addInlineNoScript(script.content);
//           } else if (script.type === "external") {
//             CookieAlert.addExternalScript(script.src, script.async);
//           }
//         }
//       }
//     },
//     cookiesEnabled: function () {
//       try {
//         return localStorage.getItem("enable-cookies") === "true";
//       } catch (e) {
//         return false;
//       }
//     },
//     messageHidden: function () {
//       try {
//         return localStorage.getItem("message-hidden-cookies") === "true";
//       } catch (e) {
//         return false;
//       }
//     },
//     hideMessage: function () {
//       try {
//         localStorage.setItem("message-hidden-cookies", "true");
//       } catch (e) {}
//     },
//     enableCookies: function () {
//       try {
//         localStorage.setItem("enable-cookies", "true");
//       } catch (e) {}
//     },
//     disableCookies: function () {
//       try {
//         localStorage.setItem("enable-cookies", "false");
//       } catch (e) {}
//     },
//     showAlert: function () {
//       let $cookieAlert = jQuery("<div>").attr("id", "cookiealert");
//       let message = CookieAlert.message_html;

//       $cookieAlert[0].innerHTML = message
//         .split("%privacy_policy_url%")
//         .join(CookieAlert.privacy_policy_url)
//         .split("%cookies_notice_url%")
//         .join(CookieAlert.cookies_notice_url);
//       $cookieAlert.appendTo(".off-canvas-content");

//       let style = document.createElement("style");
//       style.type = "text/css";
//       if (style.styleSheet) {
//         style.styleSheet.cssText = CookieAlert.message_styles;
//       } else {
//         style.appendChild(document.createTextNode(CookieAlert.message_styles));
//       }

//       $cookieAlert.append(style);
//     },
//     acceptAlert: function () {
//       jQuery("body > #cookiealert").remove();
//       CookieAlert.enableCookies();
//       CookieAlert.hideMessage();

//       let current_url = location.href.toString();
//       if (current_url.split("?").length > 0) {
//         //current_url += '&allowcookies=1';
//       } else {
//         //current_url += '?allowcookies=1';
//       }

//       location.href = current_url;
//     },
//   };

//   window.CookieAlert = CookieAlert;

//   jQuery(document).ready(function () {
//     CookieAlert.init();
//   });
// })();

$(function () {
  // $(".language-selector .menu a").on("click", function () {
  //   let href = $(
  //     '[rel="alternate"][hreflang="' +
  //       $(this).text().trim().toLowerCase() +
  //       '"]'
  //   ).attr("href");
  //   location.href = href;
  // });

  $("#disclaimer-button").on("click", function () {
    let $embedChange = $(this).closest(".responsive-embed.widescreen");
    $embedChange.css("margin-bottom", "0");
    $(this).parents(".virtual-tour__disclaimer").remove();
  });
});

(function () {
  // Initialize
  var bLazy = new Blazy();

  $("#promotion-gallery-tabs").on(
    "change.zf.tabs down.zf.accordion",
    function () {
      bLazy = new Blazy();
      window.dispatchEvent(new Event("resize"));
    }
  );
})();

// function resetCookieConfiguration() {
//   localStorage.clear();
//   let cookies = document.cookie.split(";");
//   for (let i = 0; i < cookies.length; i++) {
//     let cookie = cookies[i];
//     let eqPos = cookie.indexOf("=");
//     let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
//     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
//   }
//   alert("Configuración de cookies restablecida.");
// }

$(document).ready(function () {
  var $shareBlock = $(".share");

  if ($shareBlock.length) {
    var currentUrl = location.href.toString();
    var currentTitle = document.title;
    var $shareImage = $("meta[property='og\\:image']").attr("content");

    var $facebookLink = $(".facebook__link");
    var $twitterLink = $(".twitter__link");
    var $linkedinLink = $(".linkedin__link");
    var $pinterestLink = $(".pinterest__link");
    var $whatsappLink = $(".whatsapp__link");

    $facebookLink.attr(
      "href",
      "https://www.facebook.com/sharer/sharer.php?u=" + currentUrl
    );

    $twitterLink.attr(
      "href",
      "https://twitter.com/intent/tweet?text=" +
        currentTitle +
        "&" +
        "url=" +
        currentUrl
    );

    $linkedinLink.attr(
      "href",
      "https://www.linkedin.com/shareArticle?mini=true&url=" + currentUrl
    );

    $pinterestLink.attr(
      "href",
      "http://pinterest.com/pin/create/button/?url=" +
        currentUrl +
        "&media=" +
        $shareImage +
        "&description=" +
        currentTitle
    );

    $whatsappLink.attr(
      "href",
      "https://api.whatsapp.com/send?text=" + currentTitle + " – " + currentUrl
    );
  }
});
